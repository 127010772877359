<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <!-- <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button> -->
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <h1 class="bigTitle">變更密碼</h1>
            <ValidationProvider mode="aggressive" name="目前的密碼" rules="required" v-slot="{ errors }" tag="div" class="relative">
              <vs-input
                type="password"
                v-model="oldPassword"
                placeholder="目前的密碼"
                :visiblePassword="visiblePassword"
                @click-icon="visiblePassword = !visiblePassword"
              >
                <template #icon>
                  <svg-icon v-if="oldPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                  <svg-icon v-else-if="visiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                  <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                </template>
              </vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>

            <ValidationProvider mode="aggressive" name="新密碼" rules="required|password" v-slot="{ errors }" tag="div" class="relative">
              <vs-input
                type="password"
                v-model="newPassword"
                placeholder="新密碼"
                :visiblePassword="visiblePassword"
                @click-icon="visiblePassword = !visiblePassword"
              >
                <template #icon>
                  <svg-icon v-if="newPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                  <svg-icon v-else-if="visiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                  <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                </template>
              </vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>

            <ValidationProvider
              mode="aggressive"
              name="再次確認密碼"
              rules="required|confirmed:新密碼"
              v-slot="{ errors }"
              tag="div"
              class="relative"
            >
              <vs-input
                type="password"
                v-model="confirmPassword"
                placeholder="再次確認密碼"
                :visiblePassword="visiblePassword"
                @click-icon="visiblePassword = !visiblePassword"
              >
                <template #icon>
                  <svg-icon v-if="confirmPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                  <svg-icon v-else-if="visiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                  <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                </template>
              </vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>

            <vs-button class="self-end" size="large" ref="changePasswordBtn" :disabled="invalid" @click="changePassword(invalid)">
              <span class="font-medium">儲存</span>
            </vs-button>
          </ValidationObserver>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Account',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      visiblePassword: false,
    }
  },
  created() {
    
  },
  methods: {
    // 修改密碼
    changePassword(invalid) {
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.changePasswordBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'backstage/accounts/pawd',
        method: 'put',
        data: {
          password: this.oldPassword,
          newpassword: this.newPassword,
        },
      }).then((res) => {
        loading.close()
        if (res.data.status) {
          this.$myVS.openNoti(res.data.message, '', undefined)
          setTimeout(() => {
            location.reload()
          }, 1000);
          
        } else {
          this.$myVS.openNoti('發生錯誤', res.data.message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}
</style>
