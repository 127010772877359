var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))])]),_c('main',{staticClass:"space-y-5"},[_c('section',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-6"},[_c('ValidationObserver',{staticClass:"box flex flex-col",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"bigTitle"},[_vm._v("變更密碼")]),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"目前的密碼","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"目前的密碼","visiblePassword":_vm.visiblePassword},on:{"click-icon":function($event){_vm.visiblePassword = !_vm.visiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.oldPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.visiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"新密碼","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"新密碼","visiblePassword":_vm.visiblePassword},on:{"click-icon":function($event){_vm.visiblePassword = !_vm.visiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.newPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.visiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"再次確認密碼","rules":"required|confirmed:新密碼","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"再次確認密碼","visiblePassword":_vm.visiblePassword},on:{"click-icon":function($event){_vm.visiblePassword = !_vm.visiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.confirmPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.visiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('vs-button',{ref:"changePasswordBtn",staticClass:"self-end",attrs:{"size":"large","disabled":invalid},on:{"click":function($event){return _vm.changePassword(invalid)}}},[_c('span',{staticClass:"font-medium"},[_vm._v("儲存")])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }